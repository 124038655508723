import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './pages/home'
//import Navbar from './components/navbar'
import './App.css'
import { Provider } from 'react-redux'
import store, { saveState } from './redux/store'
import AutoContext from './redux/autocontext'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AutoContext>
          {/*<Navbar />*/}
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </AutoContext>
      </Router>
    </Provider>
  );
}

export default App;
