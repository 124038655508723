import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.dominio = "greencardpv.com";
window.protocolo = "https://";
window.protocoloNoSSL = "https://";

window.subdominio = window.location.host.split('.')[0];
window.rutasubdominio = window.location.host;

//window.subdominio = 'capjuan';
//window.rutasubdominio = 'capjuan.greencardpv.com';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
