import React, { useState, useEffect } from 'react'
import './styles.css'
import { Element } from "react-scroll";
import { connect } from 'react-redux'
import Navbar from './../components/navbarContacto'
import NavbarMain from './../components/navbar'
import Modal from '../components/modal'
import { getMediaByUsername } from 'nanogram.js';
import { Helmet } from "react-helmet"
function Home(props) {
    const [data, setData] = useState({})
    const [modal, setModal] = useState(false)
    const [photos, setPhotos] = useState([{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }, { key: 6 }])
    let slideIndex = 0;
    const handleService = (data) => {
        setData(data)
        setModal(true)
    }
    
        const slider = () => {
            
                
            let i;
            var slides = document.getElementsByClassName("heroImagen");
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            slideIndex++;
            if (slideIndex > slides.length) { slideIndex = 1 }
            slides[slideIndex - 1].style.display = "block";
            
        }
    
    useEffect(async () => {
            setInterval(() => slider(), 2000);
            if (props.data.activo == "1") {
                document.getElementById('slogan').innerHTML = props.data.Slogan
                document.getElementById('slogan2').innerHTML = props.data.Slogan2
                if(props.data.TitleFeed){
                    document.getElementsByClassName('titleFeed')[0].innerHTML = props.data.TitleFeed
                }
            }
    }, [])

    const handleShare = async (e, url) => {
        // Evitamos el comportamiento por default del enlace
        e.preventDefault();
        const shareData = {
            title: `${props.data.titleHtml}`,
            text: '¡Hola! te comparto esta tarjeta digital',
            url: `${window.protocoloNoSSL}/${window.subdominio}.greencardpv.com`,
            image: `${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo192.png`,
            description: `${props.data.titleHtml}`
        }
        await navigator.share(shareData)
    }
    if (props.data.activo == "0") {
        return (
            <div className="wrappContainer" style={{ backgroundColor: `#${props.data.ColorDegradado}` }}>
                <Helmet>
                    <title>{props.data.titleHtml}</title>
                    <link rel="shortcut icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/${props.data.Favicon}`} sizes="64x64 32x32 24x24 16x16" />
                    <link rel="apple-touch-icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo192.png`} sizes="192x192"/>
                    <link rel="apple-touch-icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo512.png`} sizes="512x512"/>
                    <meta name="description" content={`${props.data.titleHtml}`} />
                    <meta name="theme-color" content={`#${props.data.ColorDegradado}`} />
                    <style>{props.data.colorBoton}</style>
                </Helmet>
                <div className="section hero" id="Section1">
                    <div className="heroImagen" style={{backgroundImage: `url(https://greencardpv.com/plantilla_greencardpv/estudiomrgreen-greencardpv.jpg)`}}></div>
                </div>
            </div>
        )
    }else{
        return (
            <div>
            <NavbarMain />
            <div className="wrappContainer" style={{ backgroundColor: `#${props.data.ColorDegradado}` }}>
                <Helmet>
                    <title>{props.data.titleHtml}</title>
                    <link rel="shortcut icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/${props.data.Favicon}`} sizes="64x64 32x32 24x24 16x16" />
                    <link rel="apple-touch-icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo192.png`} sizes="192x192"/>
                    <link rel="apple-touch-icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo512.png`} sizes="512x512"/>
                    <meta name="description" content={`${props.data.titleHtml}`} />
                    <meta name="theme-color" content={`#${props.data.ColorDegradado}`} />
                    <style>{props.data.colorBoton}</style>
                </Helmet>
                <div className="section hero" id="Section1">
                    {props.data.TotalSlider.map(hero => {
                        if (hero == 1) {
                            return (
                                <div className="heroImagen" key={hero} style={{
                                    backgroundImage: `url("${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/images/hero/${hero.url_logo}")`
                                }}> </div>
                            )
                        } else {
                            return (
                                <div className="heroImagen hiden" key={hero} style={{
                                    backgroundImage: `url("${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/images/hero/${hero.url_logo}")`
                                }}> </div>
                            )
                        }
                    })}
                    <div className="containerHero">
                        <div className="slogan" id="slogan" style={{ fontFamily: props.data.FuenteSlogan }}></div>
                        <div className="logo">
                            <img src={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/images/${props.data.UrlLogo}`} alt="" />
                        </div>
                        <div>
                            <div className="slogan2" id="slogan2"></div>
                            <nav class="idiomas" style={{ fontFamily: props.data.Fuente }}>
                            {Object.values(props.data.TotalServices).map(serviceExtra => {
                                if(serviceExtra.tipo_servicio==2){
                                    return (
                                        <div class="idiomas-item" onClick={() => handleService(serviceExtra)}>{serviceExtra.nombre}</div>
                                    )
                                }
                            })}
                            </nav>
                        </div>
                        <div className="vistas" style={{ fontFamily: props.data.Fuente }}>
                            <i className="fas fa-eye"></i><strong> {props.data.Vistas}</strong>
                        </div>
                    </div>
                    <div className="degradado" style={{ background: `linear-gradient(rgba(255,255,255,0) ${props.data.porcentajeDegradado}%, #${props.data.ColorDegradado})` }}></div>
                </div>
                <div className="section" id="Section2" style={{ fontFamily: props.data.Fuente }}>
                    <Navbar data={props.data} />
                    <div className="categorias">
                        <div className="containerCategorias">
                            {Object.values(props.data.TotalServices).map(service => {
                                if(service.tipo_servicio==1){
                                    return (
                                        <div key={service.Titulo} className="serviceTag" style={{ backgroundImage: `url("${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/images/services${service.url_img}")` }}>
                                            <div className="handleCick" onClick={() => handleService(service)}></div>
                                            <div className="degradadoTag" style={{ background: `linear-gradient(rgba(255,255,255,0) ${props.data.porcentajeDegradadoCat}%, #000)` }}></div>
                                            <div className="ContectService">
                                                <article>
                                                    <strong>{service.nombre}</strong>
                                                    <br />
                                                    <div className="contectDescripcion">{service.descripcion}</div>
                                                </article>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                {props.data.TotalFeed!=0 && <div className="sectionFeed" id="Section3">
                    <div className="titleFeed">
                        
                    </div>
                    <div className="feed">
                        <div className="containerFeed">
                            {props.data.TotalFeed.map(feed => {
                                return (
                                    <a href={`${feed.link}`}><img className="feedImagen" key={feed.id_feed} src={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/images/instagram/${feed.url_imagen}`} alt="" /></a>
                                )
                            })}
                        </div>
                    </div>
                </div>}
                <div className="greenCard">
                    <strong style={{ fontFamily: props.data.Fuente }}>{window.subdominio}.greencardpv.com</strong>
                </div>
                <div className="contentMedia">
                    <div className="socialMedia">
                        {props.data.Facebook &&
                            <a href={props.data.Facebook} className="socialIcon"><i className="fab fa-facebook contactIcon"></i></a>
                        }
                        {props.data.Instagram &&
                            <a href={props.data.Instagram} className="socialIcon"><i className="fab fa-instagram contactIcon"></i></a>
                        }
                        {props.data.Youtube &&
                            <a href={props.data.Youtube} className="socialIcon"><i className="fab fa-youtube contactIcon"></i></a>
                        }
                        {props.data.Ubicacion &&
                            <a href={props.data.Ubicacion} className="socialIcon"><i className="fa fa-map-marker contactIcon"></i></a>
                        }
                        <a href="" onClick={(eve) => handleShare(eve, `https://${props.data.Subdominio}.greencardpv.com/`)} className="socialIcon"><i className="fas fa-share-alt contactIcon"></i></a>
                    </div>
                    
                    {props.data.sitioWeb && <div className="created">
                        <a href={props.data.sitioWeb}>{props.data.sitioWeb.replace("https://","")}</a>
                    </div>}
                    {props.data.CreatedBy==1 && <div className="createdBy">
                        Creado por <a href="https://www.greencardpv.com">Greencardpv</a>
                    </div>}
                </div>
                <Modal modal={modal} data={data} function={setModal} />
            </div>
        </div>
        )
    }
}
const PropsStore = state => {
    return {
        data: state.dataPlantilla.data
    }
}

export default connect(PropsStore, null)(Home)