import React, { useState, useEffect } from 'react'
import './estilosModal.css'
import {connect} from 'react-redux'
function Modal(props) {
    const [show, toggleShow] = React.useState(false);

    useEffect(() => {
        if (props.data.subservice) {
            if ( props.data.subservice.find(service => service.tipo === "1")) {
                const value = props.data.subservice.find(service => service.tipo === "1")
                document.getElementById('incluye').innerHTML=value.descripcion
            }
        }

    }, [props.data])
    if (props.modal === true) {

        const Incluye = () => {
            document.getElementById('incluye').classList.toggle('hidden')
        }
        const descargarPdf = (ruta) => {
            window.open(``)
        }
        const openPage = (url) => {
            if(url){
                window.location.href=url;
            }
        }
        return (
            <div className="Modal" style={{ fontFamily: props.Plantilla.FuenteContenido }}>
                <div className="ModalContent">
                    <button className="buttonClose" onClick={() => props.function(false)}>x</button>
                    <h3 className="titleModal" style={{ fontFamily: props.Plantilla.Fuente }}>{props.data.nombre}</h3>
                    <article id="descripcion_completa" dangerouslySetInnerHTML={{__html: props.data.descripcion_completa}}></article>
                    
                    <div className={`incluye hidden${show}`} id="incluye"></div>
                    <div className="Gallery">
                        {props.data.subservice && props.data.subservice.filter(service => service.tipo === '3') && props.data.subservice.filter(service => service.tipo === "3").map(img => {
                            return (
                                <div className="imgGallery"
                                    key={img}
                                    style={{ backgroundImage: `url("https://greencardpv.com/${window.subdominio}.greencardpv.com/images/services${img.url_contenido}")` }}
                                    onClick={() => openPage(img.link)}
                                ></div>
                            )
                        })}
                    </div>
                    <div className="sectionButtonsModal">
                        {props.data.subservice && props.data.subservice.filter(service => service.tipo === '1').length>0&&
                            <div className="llamadoAccion">
                                <div className="containerAction" onClick={() => toggleShow(!show)}>
                                    <i className="fas fa-plus"></i> {props.Plantilla.BtnDesglose}
                            </div>
                        </div>}
                        {props.data.subservice && props.data.subservice.filter(service => service.tipo === '2').length>0&&
                        <a className="buttonModal" href={`https://greencardpv.com/${window.subdominio}.greencardpv.com/images/services${props.data.subservice.find(service => service.tipo === "2").url_contenido}`} >
                                  <i className="far fa-file"></i> Descargar PDF
                        </a>}

                        {props.data.subservice && props.data.subservice.filter(service => service.tipo === '7').length>0&&
                        <div className="llamadoAccion">
                            <a href={`${props.data.subservice.find(service => service.tipo === "7").url_contenido}`} className="containerAction">
                                <i className="fas fa-calendar-check"></i>&nbsp;&nbsp;{`${props.data.subservice.find(service => service.tipo === "7").descripcion}`}
                            </a>
                        </div>}
                        {props.data.subservice && props.data.subservice.filter(service => service.tipo === '8').length>0&&
                        <div className="llamadoAccion">
                            <a href={`${props.data.subservice.find(service => service.tipo === "8").url_contenido}`} className="containerAction">
                                <i className={`${props.data.subservice.find(service => service.tipo === "8").class_name_contenido_service}`}></i>&nbsp;&nbsp;{`${props.data.subservice.find(service => service.tipo === "8").descripcion}`}
                            </a>
                        </div>}
                        {props.data.subservice && props.data.subservice.filter(service => service.tipo === '9').length>0&&
                        <div className="llamadoAccion">
                            <a href={`${props.data.subservice.find(service => service.tipo === "9").url_contenido}`} className="containerAction">
                                <i className="fas fa-globe"></i>&nbsp;&nbsp;{`${props.data.subservice.find(service => service.tipo === "9").descripcion}`}
                            </a>
                        </div>}

                        {props.data.subservice && props.data.subservice.filter(service => service.tipo === '4').length>0&&
                        <a className="buttonModal" href={`${props.data.subservice.find(service => service.tipo === "4").url_contenido}`}>
                                  <i className="fa fa-link"></i> {`${props.data.subservice.find(service => service.tipo === "4").descripcion}`}
                        </a>}
                        {props.data.btn_more_info==1 && 
                        <div className="llamadoAccion">
                            <a className="containerAction" href={`https://wa.me/${props.Plantilla.Whatsapp}?text=Excelente%20día,%20 vengo%20de%20tu%20*Greencardpv*%20quiero%20mas%20información%20sobre%20${props.data.nombre}`}>
                                <i className="fab fa-whatsapp"></i>&nbsp;&nbsp;Más Información
                            </a>
                        </div>
                        }
                    </div>
                </div>
                <div className="ModalCover" onClick={() => props.function(false)}></div>
            </div >
        )
    } else {
        return (false)
    }
}
const PropsStore = state => {
    return {
        Plantilla:state.dataPlantilla.data
    }
}

export default connect(PropsStore,null)(Modal)