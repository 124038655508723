import React from 'react'

export default function loading() {
    return (
        <div className="loading">
            <div className="">
                <svg width="205" className='logoCarga' height="36" viewBox="0 0 205 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.284 19.024V23.488H18V24.892C18 28.636 15.912 29.752 13.248 29.752C9.972 29.752 7.524 26.98 7.524 21.04C7.524 15.1 9.936 12.328 13.068 12.328C14.796 12.328 16.128 13.336 17.064 15.028L22.428 12.904C20.088 8.8 16.596 7.72 13.212 7.72C5.832 7.72 1.512 13.012 1.512 21.04C1.512 29.068 5.184 34.36 11.628 34.36C14.58 34.36 16.704 33.676 18.216 31.696C18.36 33.28 19.476 34.36 21.132 34.36C22.032 34.36 22.896 34.108 23.832 33.604V19.024H13.284Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M38.1577 15.424C36.2857 15.424 34.4498 16.252 33.1178 18.592C33.1178 16.72 32.2177 15.424 30.7057 15.424C29.7337 15.424 28.6898 15.748 27.7178 16.18V34H33.2978V26.368C33.2978 21.868 34.7378 20.32 37.0418 20.32C37.9058 20.32 38.6977 20.572 39.3097 20.968L40.9298 16.036C40.1018 15.676 39.1297 15.424 38.1577 15.424Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M51.0531 30.184C48.7131 30.184 47.5251 29.068 47.1291 27.16C47.9211 27.232 48.7131 27.304 49.5051 27.304C55.0851 27.304 58.4331 25.144 58.4331 21.508C58.4331 17.728 55.7691 15.424 51.1971 15.424C45.1131 15.424 41.5491 19.132 41.5491 25.324C41.5491 31.012 44.9331 34.36 50.6571 34.36C53.6811 34.36 56.1651 33.64 58.3971 32.236L56.2011 28.744C54.7611 29.68 53.1051 30.184 51.0531 30.184ZM50.6571 19.744C52.1691 19.744 53.0331 20.536 53.0331 21.76C53.0331 23.272 51.7371 24.136 49.4331 24.136C48.6771 24.136 47.8491 24.1 47.0211 24.028C47.3091 21.328 48.6411 19.744 50.6571 19.744Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M70.5296 30.184C68.1896 30.184 67.0016 29.068 66.6056 27.16C67.3976 27.232 68.1896 27.304 68.9816 27.304C74.5616 27.304 77.9096 25.144 77.9096 21.508C77.9096 17.728 75.2456 15.424 70.6736 15.424C64.5896 15.424 61.0256 19.132 61.0256 25.324C61.0256 31.012 64.4096 34.36 70.1336 34.36C73.1576 34.36 75.6416 33.64 77.8736 32.236L75.6776 28.744C74.2376 29.68 72.5816 30.184 70.5296 30.184ZM70.1336 19.744C71.6456 19.744 72.5096 20.536 72.5096 21.76C72.5096 23.272 71.2136 24.136 68.9096 24.136C68.1536 24.136 67.3256 24.1 66.4976 24.028C66.7856 21.328 68.1176 19.744 70.1336 19.744Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M92.6702 15.424C90.4382 15.424 88.3142 16.18 86.7662 18.376C86.6942 16.612 85.7942 15.424 84.3542 15.424C83.3822 15.424 82.3382 15.748 81.3662 16.18V34H86.9462V24.928C86.9462 21.112 88.4582 19.816 90.8702 19.816C92.5622 19.816 93.4622 21.148 93.4622 23.236V34H99.0422V22.264C99.0422 17.944 96.7022 15.424 92.6702 15.424Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M118.895 32.272L117.131 28.384C115.871 29.32 113.927 29.932 112.235 29.932C109.607 29.932 107.987 28.06 107.987 24.856C107.987 21.688 109.319 19.816 111.587 19.816C112.883 19.816 113.747 20.752 114.215 22.156L118.283 20.536C117.131 16.324 113.531 15.424 111.299 15.424C106.223 15.424 102.335 18.916 102.335 24.856C102.335 30.832 105.863 34.36 111.767 34.36C114.251 34.36 116.915 33.712 118.895 32.272Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M129.215 15.424C126.191 15.424 123.707 16.18 121.403 17.584L123.635 21.04C125.075 20.104 126.695 19.6 128.279 19.6C130.619 19.6 131.339 20.788 131.447 22.768C130.691 22.732 129.935 22.732 129.215 22.732C123.563 22.732 120.359 24.964 120.359 28.78C120.359 32.236 122.555 34.36 126.191 34.36C128.171 34.36 130.151 33.64 131.627 31.552C131.735 33.208 132.635 34.36 134.039 34.36C135.047 34.36 136.055 34.036 137.027 33.604V22.264C137.027 17.944 134.147 15.424 129.215 15.424ZM127.739 30.256C126.299 30.256 125.651 29.428 125.651 28.276C125.651 26.8 126.983 25.936 129.359 25.936C130.007 25.936 130.691 25.936 131.447 25.972C131.447 29.068 129.647 30.256 127.739 30.256Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M151.502 15.424C149.63 15.424 147.794 16.252 146.462 18.592C146.462 16.72 145.562 15.424 144.05 15.424C143.078 15.424 142.034 15.748 141.062 16.18V34H146.642V26.368C146.642 21.868 148.082 20.32 150.386 20.32C151.25 20.32 152.042 20.572 152.654 20.968L154.274 16.036C153.446 15.676 152.474 15.424 151.502 15.424Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M170.555 5.92C169.583 5.92 168.539 6.244 167.567 6.676V15.496C166.523 15.46 165.587 15.424 164.903 15.424C158.567 15.424 154.823 19.132 154.823 25.504C154.823 31.084 157.595 34.36 161.807 34.36C164.003 34.36 166.199 33.64 167.747 31.516C167.855 33.172 168.755 34.36 170.159 34.36C171.131 34.36 172.175 34.036 173.147 33.604V8.512C173.147 7 172.211 5.92 170.555 5.92ZM167.567 24.892C167.567 28.708 166.055 29.968 163.895 29.968C161.699 29.968 160.475 28.312 160.475 25.504C160.475 21.76 162.383 19.564 165.407 19.564C166.019 19.564 166.739 19.564 167.567 19.6V24.892Z" stroke="#59DDEC" stroke-width="2" mask="url(#path-1-outside-1)" />
                    <path d="M181.636 25.52V20.7096C182.132 20.7264 182.588 20.74 182.936 20.74C185.134 20.74 186.947 20.0914 188.212 18.83C189.477 17.5684 190.132 15.7544 190.132 13.544C190.132 11.6069 189.651 10.0032 188.748 8.87279C187.834 7.73049 186.523 7.116 184.976 7.116C183.689 7.116 182.364 7.46403 181.296 8.46034C181.204 8.22773 181.084 8.01477 180.933 7.8287C180.564 7.37463 180.037 7.116 179.408 7.116C178.659 7.116 177.888 7.36303 177.213 7.66309L176.916 7.79506V8.12V25.52V26.02H177.416H181.136H181.636V25.52ZM199.593 7.12217L199.156 7.05276L199.034 7.47885L197.042 14.4629L197.039 14.4729L197.037 14.4831L196.964 14.7882L196.861 14.2843L196.857 14.2642L196.851 14.2445L195.388 9.20664C195.388 9.20627 195.388 9.2059 195.388 9.20553C195.171 8.44801 194.803 7.91054 194.32 7.56824C193.842 7.22844 193.3 7.116 192.795 7.116C191.894 7.116 190.9 7.40179 190.3 7.72905L189.924 7.93404L190.069 8.33695L194.317 20.169L194.435 20.5H194.787H198.771H199.124L199.242 20.1681L203.466 8.33611L203.669 7.76858L203.073 7.67417L199.593 7.12217ZM182.576 16.98C182.293 16.98 181.981 16.98 181.636 16.9722V13.952C181.636 12.7344 181.885 12.0176 182.219 11.6111C182.536 11.2241 182.992 11.044 183.608 11.044C184.195 11.044 184.604 11.2615 184.883 11.639C185.177 12.0379 185.364 12.6685 185.364 13.544C185.364 14.7144 185.071 15.5705 184.603 16.1256C184.144 16.6696 183.47 16.98 182.576 16.98Z" stroke="#59DDEC" />
                    <path d="M79.4327 10.6606C86.0184 5.99681 100.887 0.622048 116.62 10.8092C117.53 11.3983 118.774 11.1976 119.41 10.2862C120.042 9.38137 119.819 8.11831 118.882 7.50814C101.242 -3.9769 84.4172 2.15157 76.9419 7.5255C76.016 8.19111 75.9196 9.4722 76.6136 10.3173C77.3187 11.176 78.5595 11.279 79.4327 10.6606Z" stroke="#59DDEC" stroke-linejoin="round" />        
                </svg>
            </div>
        </div>
    )
}
