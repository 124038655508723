import React, { useEffect, useState } from 'react'

export default function NavbarContacto(props) {
    const [Navbar, setNavbar] = useState(0)
    const handleShare = async (e, url) => {
        // Evitamos el comportamiento por default del enlace
        e.preventDefault();
        const shareData = {
            title: `${props.data.titleHtml}`,
            text: '¡Hola! te comparto esta tarjeta digital',
            url: `${window.protocoloNoSSL}/${window.subdominio}.greencardpv.com`,
            image: `${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo192.png`,
            description: `${props.data.titleHtml}`
        }
        await navigator.share(shareData)
    }
    if(props.data.activo=="1"){
    return (
        <div className="NavbarContact" id="NavbarContact">
            <a href="" onClick={(eve) => handleShare(eve, `${window.protocoloNoSSL}${window.subdominio}.greencardpv.com`)} className="contactIcon"><i className="fas fa-share-alt"></i></a>
            {/* <a href={props.data.Ubicacion} className="contactIcon"><i className="fas fa-map-marker-alt "></i></a> */}
            <a href={`https://wa.me/${props.data.Whatsapp}?text=Excelente%20día,%20vengo%20de%20tu%20*Greencardpv*`}
                    className="contactIcon">
                    <i className="fab fa-whatsapp"></i>
                </a>
            <div className="logo3">
                <img className="logoCentro" src={`https://greencardpv.com/${window.subdominio}.greencardpv.com/images/${props.data.UrlLogoNavBar}`} alt="" />
            </div>
            {props.data.Correo && props.data.OpcionNavbar=="Correo" && <a href={`mailto:${props.data.Correo}`} className="contactIcon"><i className="fas fa-envelope "></i></a>}
            {props.data.Instagram && props.data.OpcionNavbar=="Instagram" && <a href={`${props.data.Instagram}`} className="contactIcon"><i className="fab fa-instagram "></i></a>}
            <a href={`tel:${props.data.Telefono}`} className="contactIcon"><i className="fas fa-phone-alt "></i></a>
        </div>
    )
    }
}
