import React, { useState,useEffect } from 'react'
import './estilos.css'
import { scroller,animateScroll as scroll } from "react-scroll";
import {connect} from 'react-redux'

function NavbarMain(props) {
    const [opacidad, setOpacidad] = useState(1);
    const [menu, setMenu] = useState(1)
    useEffect(async () => {
        if(props.data.activo=="1"){
            document.getElementById('slogan').innerHTML = props.data.Slogan
            window.addEventListener('scroll', () => {
                if ((window.scrollY/window.innerHeight * 2) <= 1) {
                    setMenu(1)
                    document.getElementById('down').style.display="block"
                    document.getElementById('up').style.display="none"
                }
                if ((window.scrollY/window.innerHeight * 2) >= 1.3) {
                    setMenu(2)
                    document.getElementById('down').style.display="block"
                    document.getElementById('up').style.display="none"
                }
                if ((window.scrollY/window.innerHeight * 2) >= 3) {
                    setMenu(3)
                    document.getElementById('down').style.display="none"
                    document.getElementById('up').style.display="block"
                    
                }
            })
        }
    }, [])
    const handleClick = () => {
        if (menu === 1) {
            scroller.scrollTo(('Section2').toString());
        }
        else if (menu === 2) {
            scroller.scrollTo(('Section3').toString());
        }
        else if (menu === 3) {
            scroll.scrollToTop();
        }

    }
    return (
        <div className="menu">
            <div className="wrapperMenu">
                <a href="https://www.greencardpv.com/" className="greenCard colMenu" style={{ opacity: opacidad }}>
                    <img src="../../images/greencard.png" alt="" />
                </a>
                <div className="buttonSide" onClick={() => handleClick()}>
                <i className="fas fa-chevron-down" id="down" style={{display:'block'}}></i>
                    <i className="fas fa-chevron-up hiden" id="up"  style={{display:'none'}}></i>
                </div>
                <a style={{ opacity: opacidad }} href={`https://wa.me/${props.data.Whatsapp}?text=Excelente%20día,%20vengo%20de%20tu%20*Greencardpv*`}
                    className="whatsApp colMenu">
                    <i className="fab fa-whatsapp icon"></i>
                </a>
            </div>
        </div>
    )
}

const PropsStore = state => {
    return {
        data:state.dataPlantilla.data
    }
}

export default connect(PropsStore,null)(NavbarMain)
